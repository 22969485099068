<template>
  <div class="table_wrapper">
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="desserts"
      sort-by="c1"
      :loading="loading"
      loading-text="Loading... Please wait"
      class="elevation-1"
      item-key="id"
      :search="search"
      :items-per-page="perPage"
      show-select
      :options.sync="options"
      :server-items-length="totalDesserts"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Coupons</v-toolbar-title>
          <v-btn
            small
            color="black"
            dark
            class="ml-2"
            v-on:click="selectedAction('delete')"
            v-if="selected.length > 0"
          >
            <v-icon color="white">
              mdi-close
            </v-icon>
            Delete Selected ({{ selected.length }})
          </v-btn>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            v-on:keyup="tableSearch"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="warning"
              :to="'/coupons/edit/' + item.item.id"
              class="mx-1"
              fab
              small
              elevation="2"
              v-bind="attrs"
              v-on="on"
              :disabled="actionLock"
            >
              <v-icon small>
                mdi-pencil
              </v-icon>
            </v-btn>
          </template>
          <span>Edit</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5">
          Warning!
        </v-card-title>
        <v-card-text>Are you sure of what you're doing?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="dialog = false">
            No
          </v-btn>
          <v-btn color="green" text @click="dialogActionYes">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import moment from "moment";
export default {
  name: "Coupons",
  props: {
    member_id: {
      required: false,
    },
  },
  data() {
    return {
      selected: [],
      dialog: false,
      moment: moment,
      actionLock: false,
      options: {},
      search: "",
      activeTab: 0,
      loading: true,
      token: localStorage.getItem("authToken"),
      headers: [
        { text: "ID", value: "c1", sortable: false },
        { text: "Code", value: "c2", sortable: false },
        { text: "Description", value: "c3", sortable: false },
        { text: "Discount", value: "c4", sortable: false },
        { text: "Is Used", value: "c5", sortable: false },
        { text: "Is Expired", value: "c6", sortable: false },
        { text: "Expire Date", value: "c7", sortable: false },
        { text: "Created at", value: "c8", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ],
      desserts: [],
      totalDesserts: 0,
      page: 1,
      perPage: 5,
      itemsData: [],
      mfields: [],
      timer: null,
      dialogAction: {
        url: "",
        data: null,
        type: "",
      },
    };
  },

  watch: {
    options: {
      handler() {
        this.getDataFromApi(this.activeTab);
      },
      deep: true,
    },
  },
  methods: {
    parseLink: function(link) {
      return link.substr(0, 50) + "...";
    },
    parseDateTime: function(date) {
      let dta = new Date(date).getTime();
      return this.moment(dta).fromNow();
    },
    selectedAction: function(type) {
      if (this.selected.length == 0) {
        return false;
      } else {
        const selectedMap = this.selected.map(function(val, index) {
          return val.id;
        });

        let url = "";

        if (type == "delete") {
          url = "https://gowawe.com/api/admin/coupon/bulk-delete";
        }

        this.dialog = true;
        this.dialogAction = {
          url: url,
          type: type,
          data: selectedMap,
        };
      }
    },
    dialogActionYes: function() {
      if (this.dialogAction.data == null) {
        return false;
      }
      this.dialog = false;
      this.selected = [];

      axios
        .post(this.dialogAction.url, {
          ids: this.dialogAction.data,
          api_token: this.token,
        })
        .then((res) => {
          this.getDataFromApi(this.activeTab);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getRefreshData: function() {
      this.selected = [];
      this.getDataFromApi(this.activeTab);
    },
    tableSearch: function() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.getDataFromApi(this.activeTab);
      }, 800);
    },
    getDataFromApi(a) {
      this.activeTab = a;
      this.loading = true;
      this.fakeApiCall().then((data) => {
        this.desserts = [];
        data.itemsR.forEach((item, index) => {
          const key = {
            c1: item.id,
            c2: item.code,
            c3: item.description,
            c4: item.percent + "%",
            c5: item.is_used ? "Yes" : "No",
            c6: item.is_expired ? "Yes" : "No",
            c7: item.expire_date,
            c8: item.created_at,
            item: item,
            id: item.id,
          };
          this.desserts.push(key);
        });

        this.totalDesserts = data.total;
        this.loading = false;
        this.actionLock = false;
      });
    },
    fakeApiCall() {
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        this.page = page;
        this.perPage = itemsPerPage == -1 ? this.totalDesserts : itemsPerPage;

        this.getDesserts().then((res) => {
          this.itemsData = res.data;

          const total = res.total;

          let itemsR = this.itemsData;

          setTimeout(() => {
            resolve({
              itemsR,
              total,
            });
          }, 1000);
        });
      });
    },
    getDesserts() {
      const fields = [];

      if (this.search) {
        fields.push({
          field: "term",
          op: "=",
          value: this.search,
        });
      }

      const sort = [
        {
          dir: "desc",
          field: "created_at",
        },
      ];

      return axios
        .post(
          process.env.VUE_APP_API_URL +
            "coupon/filter?page=" +
            this.page +
            "&per_page=" +
            this.perPage +
            "&api_token=" +
            this.token,
          {
            fields,
            sort: sort,
            type: "",
          }
        )
        .then(function(response) {
          return response.data.data;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
  },
  mounted() {},
  computed: {
    ...mapGetters("auth", ["user"]),
  },
};
</script>
